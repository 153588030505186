import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";




export const GlobalContext = createContext(null);

export const GlobalState = ({ children }) => {


    const [bannerSectionOnly, setBannerSectionOnly] = useState(false);
    const [isZoomOut, setIsZoomOut] = useState(false);
    const [hideText, setHideText] = useState(false);
    const [enableMidLogo, setEnableMidLogo] = useState(false);
    const [enableTopLogo, setEnableTopLogo] = useState(false);
    const [enableContent, setEnableContent] = useState(false);
    const [enableHeader, setEnableHeader] = useState(false);
    const [enableHeaderLogo, setEnableHeaderLogo] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [navbarActive, setNavbarActive] = useState(null);
    const [showMobileSidebar, setShowMobileSidebar] = useState(false)

    const handleHomeExplore = () => {
        setBannerSectionOnly(true)
        setHideText(true)
        setTimeout(() => {
            setIsZoomOut(true)
            setEnableMidLogo(true)
        }, 2000);
        setTimeout(() => {
            setEnableMidLogo(false)
            setEnableTopLogo(true)
        }, 4000);
        setTimeout(() => {
            setEnableContent(true);
            setEnableHeader(true)
        }, 4500);
        setTimeout(() => {
            setEnableHeaderLogo(true);
        }, 6000);
    }
    const handleShowMobileSidebar = () => {
        setShowMobileSidebar(true)
    }
    const handleCloseMobileSidebar = () => {
        setShowMobileSidebar(false)
    }

    useEffect(() => {
        const scrollActive = () => {
            setNavbarActive(window.scrollY > 0);
            setShowMobileSidebar(false)
        };
        window.addEventListener("scroll", scrollActive);
        return () => window.removeEventListener("scroll", scrollActive);
    }, [navbarActive]);

    return (
        <GlobalContext.Provider value={{
            bannerSectionOnly,
            isZoomOut,
            hideText,
            enableMidLogo,
            enableTopLogo,
            enableContent,
            enableHeader,
            setEnableHeader,
            enableHeaderLogo,
            navbarActive,
            showMobileSidebar,
            handleHomeExplore,
            handleShowMobileSidebar,
            handleCloseMobileSidebar
        }}>
            {children}
        </GlobalContext.Provider>
    )
}