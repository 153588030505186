import React from "react";
import Ethereum from "../assets/images/ethuu.png";
import Binance from '../assets/images/svg/binance.png'
import { Link, useNavigate } from "react-router-dom";
import ImgAudVideo from "./../separate/ImgAudVideo";
import config from "./config/config";
import NoData from "./seperatemodals/nodata";
import { Token_MyList_Func } from "../actions/axioss/user.axios";
export default function Activity({ datas, Loadmore, List, userProfile, Loader }) {
  const push = useNavigate();
  { console.log('activityyyy', datas, List, userProfile) }

  return (
    <>
      <div className="inner-content inventory">
        <h3 class="profilename">{userProfile?.DisplayName}</h3>
        <h4 className="title-dashboard" data-aos="fade-right">
          Activity
        </h4>
        <div className="activity_table">
          <div className="acti_table_header aling_image_header_width">
            <div className="acti_table_singlehead"><p>Image</p></div>
            <div className="acti_table_singlehead"><p>Name</p></div>
            <div className="acti_table_singlehead"><p>Category</p></div>
            <div className="acti_table_singlehead"><p>Blockchain</p></div>
            <div className="acti_table_singlehead"><p>Quantity</p></div>
            <div className="acti_table_singlehead"><p>Price</p></div>
            <div className="acti_table_singlehead"><p>Action</p></div>
          </div>

          <div className="aling_image_body_width">
            {datas?.list?.length == 0 ? <div className="noda_div_cen">

              <NoData />
            </div> : datas?.list?.length > 0 ?
              datas?.list?.map((item, key) => {
                return (

                  <>
                    <div className="acti_table_values">
                      <div className="acti_table_singlevalue"><div
                        className="image"

                      >
                        {console.log("datainactivity", item)}
                        <ImgAudVideo
                          file={item?.CompressedFile !== undefined ? item?.CompressedFile?.split(':')[0] == 'https' ? item?.CompressedFile : `${config.IMG_URL}/nft/${item?.Creator}/Compressed/NFT/${item?.CompressedFile}` : item?.CompressedFile?.split(':')[0] == 'https' ? item.CompressedFile : `${config.IMG_URL}/nft/${item?.Creator}/Original/NFT/${item.CompressedFile}`}
                          // origFile={item?.OriginalFile?.split(':')[0] == 'https' ? item?.OriginalFile : `${config.IMG_URL}/nft/${item?.Creator}/Original/NFT/${item?.OriginalFile ? item?.OriginalFile : item?.CompressedFile}`}
                          origFile={config.IPFS + item.NFTOrginalImageIpfs}
                          thumb={`${config.IMG_URL}/nft/${item.Creator}/Compressed/NFT_THUMB/${item?.CompressedThumbFile}`}
                          type={
                            item?.CompressedFile
                              ? item?.CompressedFile?.includes(".webp") || item?.CompressedFile?.includes(".png")
                                ? "image"
                                : item?.CompressedFile.includes(".webm")
                                  ? "video"
                                  : "audio"
                              : item?.CompressedFile
                          }
                        />

                      </div></div>
                      <div className="acti_table_singlevalue"><p>{item?.TokenName?.length > 10 ? <>{item.TokenName.slice(0, 10)}...</> : item.TokenName}</p></div>
                      <div className="acti_table_singlevalue"><p>{item.Category}</p></div>
                      <div className="acti_table_singlevalue">
                        <div className="coin">
                          <img src={item.CollectionNetwork == "BNB" ? Binance : Ethereum} alt="Image" className="card_image mr-2" width={20} />
                          <p>{item.CollectionNetwork}</p>
                        </div>
                      </div>
                      <div className="acti_table_singlevalue"><p>{item.NFTQuantity}</p></div>
                      <div className="acti_table_singlevalue"><p> {item.NFTPrice}
                        {item.CoinName}</p></div>
                      <div className="acti_table_singlevalue"> <div className="activity_act_button">

                        <i className="fas fa-circle"></i>
                        {item.Activity}
                      </div></div>
                    </div>
                  </>
                );
              }) :
              <><NoData /></>}
          </div>

        </div>
        {datas?.list?.length > 0 && Loader && <div className="table-btn">
          <buton type="button" style={{ cursor: "pointer" }} className="tf-button loadmore mt-3 mb-3 py-2 px-3" onClick={Loadmore}>Load More</buton>
        </div>}

      </div>
    </>
  );
}
