import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store'
import { Provider } from 'react-redux';
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from '@web3-react/core'

import './assets/css/custom.css'
import './assets/css/customnew.css'


import { GlobalState } from './context/context';


function getLibrary(provider) {
  console.log("providerprovider");
  return new Web3Provider(provider);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store} >
    <Web3ReactProvider getLibrary={(getLibrary)}>
      <GlobalState>
        <App />
      </GlobalState>
    </Web3ReactProvider>
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
