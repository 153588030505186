/** npm import */

import React,{useState,useEffect} from "react";
import { Link, useNavigate } from 'react-router-dom'
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { getCmsContent } from "../actions/axioss/cms.axios";
import { useSelector } from 'react-redux';
import { isEmpty } from "../actions/common";
import { toast } from "react-toastify";
import { Sociallinks , Newsletter} from "../actions/axioss/user.axios";
import config from "../views/config/config"
import discard from "../assets/images/DiscordLogo.png";
import whatsapp from '../assets/images/whatsapp (1).png'
import facebook from '../assets/images/facebook.png'
import telegram from '../assets/images/telegram.png'
import linkedin from '../assets/images/linkedin.png'
import you from "../assets/images/YoutubeLogo.png";
import twitter from "../assets/images/TwitterLogo.png";
import instagram from "../assets/images/InstagramLogo.png";
/** local files import */

// import logo from '../assets/evolvex/images/logo.png'
import logo from '../assets/evolvex/images/newblacklogo.png'



/** code start */

const EvFooter = () => {
    const push = useNavigate();
    var [email,setEmail] = useState('');
    var [Error,SetError] = useState({});
    const wallet = useSelector(state => state.LoginReducer.AccountDetails);
    var [link,setLink] = useState([]);
    var [Error,SetError] = useState({});
  const [footer,setFooter]=useState({})
    const { payload } = useSelector(state => state.LoginReducer.User)
    const { Categorys } = useSelector((state) => state.LoginReducer);

    useEffect(()=>{
        var a = Getlink();
        Getandsellnft();
        // console.log("hello",a)
    },[])
    const Getandsellnft = async () => {

        var resp = await getCmsContent("footer");
        console.log("footerrr",resp?.data)
        if (resp?.status)
            setFooter(resp.data);
    }

    const Getlink=async()=>{
        
        let link_res= await Sociallinks();
        console.log("linkss",link_res?.msg);
        setLink(link_res?.msg??[])
    }

    const NewsLetter = async() =>{
        const id = toast.loading("Subscribing...");
        
        let err = {};
        if(email=='') err.email="Email Id Required";
        if(email && !(config.EMAIL).test(email)) err.email = 'Invalid Email ID Format';
        SetError(err);
        // console.log('fhbdfhbdf',email)
        if(isEmpty(err)){
            let resp = await Newsletter({email : email});
            if(resp.success === 'success'){
                toast.update(id ,{render:"Successfully Subscribed", type: "success", isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
                setEmail('');
            }
            else{
                toast.update(id ,{ render : "Already User", type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
                SetError({email:resp.msg});
            }
        }
        else{
            toast.update(id ,{ render : err.email, type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
        }
    }
    const click = async (table) => {
        push("/explore/" + table)
    }
    return (
        <footer className='ev-footer'>
            <div className='container container-width'>
                <div className="row">
                    <div className="col-12 col-md-6 col-xl-4 ">
                        <div className='ev-footer__left'>
                            <img src={logo} alt="logo" className='ev-footer__left--logo' />

                            {/* <p className='ev-footer__left--content'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto ducimus eveniet laudantium beatae eaque obcaecati sint ex impedit aliquid illo molestiae odit, facere vel quod autem placeat veritatis tempora perspiciatis.</p> */}
                            <div className="footer_p " dangerouslySetInnerHTML={{ __html: footer?.answer }}></div>
                            <div className='ev-footer__social'>
                                {/* <a href="www.facebook.com">
                                    <div className='ev-footer__link-bg'>
                                        <FaFacebookF fill='#fff' fontSize={20} />
                                    </div>
                                </a>
                                <a href="www.facebook.com">
                                    <div className='ev-footer__link-bg'>
                                        <FaTwitter fill='#fff' fontSize={20} />
                                    </div>
                                </a>
                                <a href="www.facebook.com">
                                    <div className='ev-footer__link-bg'>
                                        <FaLinkedinIn fill='#fff' fontSize={20} />
                                    </div>
                                </a> */}
                                {/* {link.map((mlink)=>(  
                                       
                                       <>
                                       {
                                          <a href={mlink.link} target="blank">
                                           
                                          <img src={`${config.IMG_URL}/socialimg/${mlink?.img}`} alt="dd"/>
                                          </a>
                                       }
                                         </>
                                      ))} */}
                                           <ul className="social-item d-flex flex-wrap align-items-center" style={{gap:"20px"}}>
                                  {console.log('linkss',link)}
                                {link.map((mlink)=>(  
                                     
                                    <div className="nm-footer__social-wrapper">
                                   
                                    {
                               (mlink.website).toLowerCase()==="facebook." &&
                                    <a href={mlink.link} target="blank">
                                        <FaFacebookF fill='#fff' fontSize={20} />
                                        {/* <img src={twitter} alt="dd" className="img-fluid"/> */}
                                        </a>
                                        }
                                
                                { 
                                   (mlink.website).toLowerCase()==="youtube" &&
                                    <a href={mlink.link} target="blank">
                                        <FaTwitter fill='#fff' fontSize={20} />
                                        {/* <img src={you} alt="dd" className="img-fluid"/>
                                       */}
                                        </a> 
                                        }
 {(mlink.website).toLowerCase() ==="instagram" &&
                                    <a href={mlink.link} target="blank">
                                         <FaLinkedinIn fill='#fff' fontSize={20} />
                                        {/* <img src={instagram} alt="dd" className="img-fluid"/> */}
                                        </a>
                                        }

                               
                                       
                                    {/* {
                                    (mlink.website).toLowerCase()==="discard" &&
                                    <a href={mlink.link} target="blank">
                                      
                                        <img src={discard} alt="dd"/>
                                        </a>
                                        } */}
                                    </div>
                                    ))}
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-2 d-flex justify-content-start justify-content-md-center mt-5 mt-md-0">
                        <nav className='ev-footer__mid ev-footer__mid-left'>
                            <h5 className='ev-footer__nav-title ev_footerTitle'>Marketplace</h5>
                            <ul>
                            {Categorys.map(item => <li className={"menu-item"} onClick={() => { click(item.value) }}><Link to="#">{item.label}</Link></li>)}
                                {/* <li>
                                    <Link>All Nft</Link>
                                </li>
                                <li>
                                    <Link>New</Link>
                                </li>
                                <li>
                                    <Link>Art</Link>
                                </li>
                                <li>
                                    <Link>Sports</Link>
                                </li>
                                <li>
                                    <Link>Utility</Link>
                                </li>
                                <li>
                                    <Link>Music</Link>
                                </li>
                                <li>
                                    <Link>Domain Name</Link>
                                </li> */}

                            </ul>
                        </nav>
                    </div>
                    <div className="col-6 col-md-3 col-xl-2 d-flex justify-content-start justify-content-md-center mt-5 mt-md-0">
                        <nav className='ev-footer__mid ev-footer__mid-right'>
                            <h5 className='ev_footerTitle'>My Account</h5>
                            <ul>
                                <li>
                                    <Link to={`/profile/${payload?.CustomUrl}`}>Profile</Link>
                                </li>
                                <li>
                                    <Link to={"/explore"}>Explore</Link>
                                </li>
                                <li>
                                    <Link   to={`/profile/${payload?.CustomUrl}`}>My Collections</Link>
                                </li>
                                <li>
                                    <Link   to={`/profile/${payload?.CustomUrl}`}
>Settings</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4 d-flex justify-content-end  mt-4 mt-md-5 mt-xl-0">
                        <div className="ev-footer__right">
                            <h5 className='ev_footerTitle'>Stay in The Loop</h5>
                            <p>Get exclusive promotions & updates straight to your inbox.
                            </p>
                            <div className='ev-footer__search'>
                                <input type="email" placeholder="Enter your email to receive updates" value={''||email} onChange={(event)=>{setEmail(event.target.value)}} required="" id="subscribe-email" className="footer_email_inpt" autoComplete="off" />
                                <button onClick={NewsLetter}>Subscribe Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default EvFooter