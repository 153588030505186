import React, { useEffect, useState } from "react";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { NavLink, Link } from "react-router-dom";
import { getCmsContent } from "../actions/axioss/cms.axios";
import { MdKeyboardArrowRight } from 'react-icons/md';
import EvFooter from "../separate/EvFooter.js";


export default function PrivacyPolicy() {

    const [privacyPolicy, SetprivacyPolicy] = useState([])
    useEffect(() => {
        privacypolicy();
    }, [])
    const privacypolicy = async () => {

        var resp = await getCmsContent("privacypolicy");
        // console.log("cmslist",resp.data)
        if (resp?.status)
            SetprivacyPolicy(resp.data);
    }
    return (
        <>
            <Header />
            <div className="privacy_policy all">
                <div className="tf-container">
                    <div className="row">
                        <section className="tf-page-title">
                            <div className="col-md-12">
                                <ul className="breadcrumbs" data-aos="fade-right">
                                    <li><NavLink to="/">Home <MdKeyboardArrowRight /></NavLink></li>
                                    <li>Privacy Policy</li>
                                </ul>
                                <h1 className="page-title-heading privacy-h" data-aos="fade-left">
                                    Your Privacy, Our Priority

                                </h1>

                            </div>
                        </section>
                    </div>
                    <div className="tf-add-nft" data-aos="fade-right">
                        <div className="content" data-aos="zoom-in">
                            <div dangerouslySetInnerHTML={{ __html: privacyPolicy?.answer }}></div>
                            {/* <p className="mission-p mt-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* <Footer /> */}
            <EvFooter />
        </>
    )
}