import React, { useState, useRef } from "react";
import Footer from "../separate/Footer";
import Header from "../separate/Header";
import { NavLink, Link } from "react-router-dom";
import authorImg from "../assets/images/banner.png";
import productImg from "../assets/images/product52.jpg";
import ethImg from "../assets/images/ethe.svg";
import Tick from "../assets/images/svg/tick.svg";
import Countdown from 'react-countdown';
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { SearchAction } from '../actions/axioss/user.axios'
import Config from "../views/config/config";
import Author from "../assets/images/product52.jpg";
import { address_showing } from "../actions/common";
import ImgAudVideo from "../separate/ImgAudVideo";
import Button from "react-bootstrap/esm/Button.js";
import { Token_List_Func } from "../actions/axioss/nft.axios"
import { MdKeyboardArrowRight } from 'react-icons/md';
import Card from "./Card.js";
import NoData from "./seperatemodals/nodata";
export default function Search() {
    const [category, setCategory] = React.useState(1);
    const { payload } = useSelector((state) => state.LoginReducer.User);
    const { key } = useParams();
    // console.log("key",key);
    const [val, Setval] = useState([]);
    const [Searchdata, SetSearch] = useState([]);
    const [LikedTokenList, setLikedTokenList] = useState([]);
    const [loading, setLoading] = useState(true)
    const [TabName, SetTabName] = useState("All");

    const [Tokens, SetTokens] = useState({
        All: { loader: true, page: 1, list: [] },
    });
    const Tabname = (newValue) => {
        SetTabName(newValue);
    };
    var LikeForwardRef = useRef();
    const collection = ([
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" },
        { img: Author, title: "SweetGirlofCandy", createdName: "MariaBrownie@1123" }]
    )
    const topCreators = ([
        { img: Author, title: "Philippa Sutton", eth: "$124.135" },
        { img: Author, title: "Megan Howard", eth: "$124.135" },
        { img: Author, title: "Talia Fitzgerald", eth: "$124.135" },
        { img: Author, title: "Kingsley Sandoval", eth: "$124.135" },
        { img: Author, title: "Frank Stevens", eth: "$124.135" },
        { img: Author, title: "Wilmot Hodges", eth: "$124.135" },
        { img: Author, title: "Prudence Higgins", eth: "$124.135" },
        { img: Author, title: "Samantha Rice", eth: "$124.135" },
        { img: Author, title: "Thomas Nicholson", eth: "$124.135" },
        { img: Author, title: "Rosemary Smart", eth: "$124.135" }]
    )
    const product = ([
        // {authorImg:authorImg ,productImg:productImg,ethImg:ethImg,title:"Avidlines",creator:"Creator",creatorName:"Frank Stevens",currency:"BNB",price:"4.53"},
        // {authorImg:authorImg ,productImg:productImg,ethImg:ethImg,title:"Avidlines",creator:"Creator",creatorName:"Frank Stevens",currency:"BNB",price:"4.53"},
        // {authorImg:authorImg ,productImg:productImg,ethImg:ethImg,title:"Avidlines",creator:"Creator",creatorName:"Frank Stevens",currency:"BNB",price:"4.53"},
        // {authorImg:authorImg ,productImg:productImg,ethImg:ethImg,title:"Avidlines",creator:"Creator",creatorName:"Frank Stevens",currency:"BNB",price:"4.53"},
        // {authorImg:authorImg ,productImg:productImg,ethImg:ethImg,title:"Avidlines",creator:"Creator",creatorName:"Frank Stevens",currency:"BNB",price:"4.53"},
        // {authorImg:authorImg ,productImg:productImg,ethImg:ethImg,title:"Avidlines",creator:"Creator",creatorName:"Frank Stevens",currency:"BNB",price:"4.53"},
        // {authorImg:authorImg ,productImg:productImg,ethImg:ethImg,title:"Avidlines",creator:"Creator",creatorName:"Frank Stevens",currency:"BNB",price:"4.53"},
        // {authorImg:authorImg ,productImg:productImg,ethImg:ethImg,title:"Avidlines",creator:"Creator",creatorName:"Frank Stevens",currency:"BNB",price:"4.53"},
        // {authorImg:authorImg ,productImg:productImg,ethImg:ethImg,title:"Avidlines",creator:"Creator",creatorName:"Frank Stevens",currency:"BNB",price:"4.53"}

    ])

    function LikeList(data) {
        setLikedTokenList(data)
    }
    useEffect(() => {
        items();
        // loadmore();
        // Explore();
    }, [key]);
    //  useEffect(() => {
    //     if (typeof Searchdata[TabName] == "undefined") {
    //         Searchdata[TabName] = { page: 1, list: [], loader: false };
    //       SetSearch(Searchdata);
    //       Explore(1, TabName);
    //     } 
    //   }, [TabName]);

    //   const Explore = async (data, tab) => {
    //     var page = data ? data : Searchdata[TabName]?.page;
    //     var SendDATA = {
    //       TabName: tab ? tab : TabName,
    //       limit: 12,
    //       page: page ?? 1,
    //       from: "search",
    //     };
    //     let Resp = await SearchAction(SendDATA);
    //     console.log("Check datad",Resp,SendDATA)
    //     if (Resp) {
    //         SetSearch({
    //         ...Searchdata,
    //         ...{
    //           [TabName]: {
    //             list: [...Searchdata[TabName].list, ...Resp.data],
    //             // loader:
    //             //   Resp.Count <= Tokens[TabName].list.length + Resp.data.length ||
    //             //   Resp.data.length === 0
    //             //     ? false
    //             //     : true,
    //             loader: ( Resp.data.length ==0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
    //             page: Searchdata[TabName].page,
    //           },
    //         },
    //       });
    //     } else
    //     SetSearch({
    //         ...Searchdata,
    //         ...{
    //           [TabName]: {
    //             list: Searchdata[TabName].list,
    //             loader: false,
    //             page: Searchdata[TabName].page,
    //           },
    //         },
    //       });
    //   };


    const items = async () => {
        // console.log("vallllllllllll",key);
        if (key) {
            Setval(key);
            // console.log("uuu",val);
            var Resp = await SearchAction({

                keyword: key,
                limit: 1000,
                page: 1,
                from: "search",
            });
            // console.log("response", Resp);
            if (Resp) {
                SetSearch(Resp);
                setLoading(false)
            }
        }
        else {

        }
    };


    const Loadmore = async (value) => {
        // console.log("loadmore");
        Searchdata.page = Searchdata.page + 1;
        SetSearch(Searchdata);
        // Explore(Searchdata[TabName].page);
        SearchAction(Searchdata.page)
    };

    var renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
        if (completed) {

            return <span>Waiting for Owner To Accept</span>
        } else {
            return <span> <span className="hourds">{formatTime(hours)} h</span> <span className="semicolan">:</span> <span className="hourds">{formatTime(minutes)} m</span> <span className="semicolan">:</span> <span className="hourds">{formatTime(seconds)} s</span> </span>;
        }
    };
    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }
    return (
        <>
            <Header />
            <div className="search">
                <section className="tf-page-title" data-aos="fade-right">
                    {/* <div className="tf-container"> */}
                    <div className="container container-width">

                        <div className="row">
                            <div className="col-md-12">

                                <ul className="breadcrumbs">
                                    <li><NavLink to="/">Home <MdKeyboardArrowRight /></NavLink></li>
                                    <li>Search</li>
                                </ul>
                                {/* <h4 className="page-title-heading">Explore</h4> */}

                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="top-menu wow fadeInUp" data-aos="fade-up">
                        {/* <div className="tf-container"> */}
                        <div className="container container-width">
                            <ul className="filter-menu">
                                <li className={category == 1 ? "active" : ""} onClick={() => setCategory(1)}><a>Token</a></li>
                                <li className={category == 2 ? "active" : ""} onClick={() => setCategory(2)}><a>User</a></li>
                                {/* <li className={category == 3 ? "active" : ""} onClick={() => setCategory(3)}><a>Collections</a></li> */}

                            </ul>
                        </div>
                    </div>
                </section>
                {category == 1 ?
                    (<>
                        <section className="tf-live-auction explore tf-filter" data-aos="fade-left">
                            {/* <div className="tf-container"> */}
                            <div className="container container-width">
                                <div className="row">
                                    {loading ?
                                        <div className="text-centre">
                                            <h3>Loading...</h3>
                                        </div>
                                        :
                                        Searchdata?.token?.data.length > 0 ?

                                            Searchdata?.token?.data?.map((data, index) =>
                                                <div
                                                    className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                                                    data-aos="zoom-in-up"
                                                >
                                                    <Card product={data}
                                                        index={index}
                                                        LikeList={LikeList}
                                                        LikedTokenList={LikedTokenList}
                                                        setLikedTokenList={setLikedTokenList}
                                                        LikeForwardRef={LikeForwardRef}
                                                    />
                                                </div>

                                            )
                                            :
                                            <NoData />}
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="btn-loadmore mt17 mb-5">
                                    <a href="#" class="tf-button loadmore" onClick={() => Loadmore("user")}>Load More</a>
                                </div>
                            </div>
                        </section>
                    </>) : (<></>)
                }
                {category == 2 ?
                    (<>
                        <section className="tf-live-auction explore tf-filter" data-aos="zoom-in">
                            {/* <div className="tf-container"> */}
                            <div className="container container-width">
                                <div className="row">
                                    {
                                        loading ?
                                            <div className="text-centre">
                                                <h3>Loading...</h3>
                                            </div>
                                            :
                                            Searchdata.user?.msg.length > 0 ?

                                                Searchdata.user?.msg?.map((data, index) => {
                                                    return (
                                                        <div className="col-xl-3 col-lg-4 col-md-6">
                                                            <div className="tf-author-wrap" data-aos="fade-up">
                                                                <span className="number">{index + 1}.</span>
                                                                <div className="tf-author">
                                                                    <div className="image">
                                                                        <img className="tf_firstimg" src={data.Profile ? `${Config.IMG_URL}/user/${data.WalletAddress}/profile/${data.Profile}` : require("../assets/images/author-detail-3.png")} alt="Image" />
                                                                        <img src={Tick} alt="Image" width="14" height="14" className="img-fluid tick" />

                                                                    </div>
                                                                    <div className="content">
                                                                        <div className="title">
                                                                            <NavLink to={`/profile/${data.CustomUrl}`}>{data.DisplayName}</NavLink>
                                                                        </div>
                                                                        <div className="price">
                                                                            <span className="price-eth"><img src={ethImg} /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <NoData />
                                    }
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="btn-loadmore mt17 mb-5">
                                    <a href="#" class="tf-button loadmore" onClick={() => Loadmore("user")}>Load More</a>
                                </div>
                            </div>
                        </section>
                    </>) : (<></>)
                }

                {category == 3 ?
                    (
                        <>
                            {/* <section className="tf-page-title" data-aos="fade-right">    
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="breadcrumbs">
                                <li><NavLink to ="/">Home</NavLink></li>
                                <li>Collection List</li>
                            </ul>
                            <h4 className="page-title-heading">Explore</h4>
                        </div>
                    </div>
                </div>                    
            </section>   */}
                            {/* <section className="tf-container" data-aos="zoom-in"> */}
                            <section className="container container-width" data-aos="zoom-in">

                                {/* <ul className="filter-menu">
                                    <li className="active">3D MODEL</li>
                                    <li>ANIME/MANGA</li>
                                    <li>CYBER PUNK</li>
                                    <li>PIXEL ART</li>
                                    <li>MUSIC</li>
                                    <li>ABSTRACT</li>
                                    <li>2D ARTS</li>
                                </ul> */}
                                <div className="row mt-5">
                                    {/* {console.log('dataaas',Searchdata)} */}
                                    {Searchdata?.collection?.data?.map((data, index) => {
                                        return (
                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                <div className="swiper-slide">
                                                    <div className="slider-item">
                                                        <div className="sc-product style1 collection collection2" >
                                                            <div className="top">
                                                                <div className="content">
                                                                    <div className="author-cl">
                                                                        <img src={`${Config.IMG_URL}/collection/${data.CollectionSymbol}/${data.CollectionProfileImage}`} alt="images" />
                                                                    </div>
                                                                    <div className="inner">
                                                                        <NavLink to={"/collection/" + data.CollectionSymbol} className="name">{data.CollectionName}</NavLink>
                                                                        <div className="create">created by <Link to={"/profile/" + data.userfield[0].CustomUrl}>{data.userfield[0].DisplayName ? data.userfield[0].DisplayName : address_showing(data.CollectionCreator)}</Link></div>
                                                                    </div>
                                                                </div>
                                                                <div className="wish-list">
                                                                    <a href="#" className="heart-icon"></a>
                                                                </div>
                                                            </div>
                                                            <a href="#">

                                                                <div className="thumb-collection">
                                                                    {data?.field[0] &&
                                                                        <div className="left-thumb">
                                                                            <NavLink to={data?.field[0]?.Link}>
                                                                                <ImgAudVideo file={`${Config.IMG_URL}/nft/${data?.CollectionCreator}/Compressed/NFT/${data?.field[0]?.CompressedFile}`}
                                                                                    origFile={`${Config.IMG_URL}/nft/${data?.CollectionCreator}/Original/NFT/${data?.field[0]?.NFTOrginalImage}`}
                                                                                    type={
                                                                                        data?.field[0]?.CompressedFile
                                                                                            ? data?.field[0]?.CompressedFile?.includes(".webp")
                                                                                                ? "image"
                                                                                                : data?.field[0]?.CompressedFile.includes(".webm")
                                                                                                    ? "video"
                                                                                                    : "audio"
                                                                                            : data?.field[0]?.CompressedFile
                                                                                    } alt="images"
                                                                                />
                                                                            </NavLink>
                                                                        </div>}

                                                                    <div className="right-thumb">
                                                                        {data?.field[1] &&
                                                                            <div className="top-cl">
                                                                                <NavLink to={data?.field[1]?.Link}>
                                                                                    <ImgAudVideo file={`${Config.IMG_URL}/nft/${data?.CollectionCreator}/Compressed/NFT/${data?.field[1]?.CompressedFile}`}
                                                                                        origFile={`${Config.IMG_URL}/nft/${data?.CollectionCreator}/Original/NFT/${data?.field[1]?.NFTOrginalImage}`}
                                                                                        type={
                                                                                            data?.field[1]?.CompressedFile
                                                                                                ? data?.field[1]?.CompressedFile?.includes(".webp")
                                                                                                    ? "image"
                                                                                                    : data?.field[1]?.CompressedFile.includes(".webm")
                                                                                                        ? "video"
                                                                                                        : "audio"
                                                                                                : data?.field[1]?.CompressedFile
                                                                                        } alt="images"
                                                                                    />
                                                                                </NavLink>
                                                                            </div>}

                                                                        <div className="bottom-cl">
                                                                            {data?.field[2] &&
                                                                                <NavLink to={data?.field[2]?.Link}>
                                                                                    <ImgAudVideo file={`${Config.IMG_URL}/nft/${data?.CollectionCreator}/Compressed/NFT/${data?.field[2]?.CompressedFile}`}
                                                                                        origFile={`${Config.IMG_URL}/nft/${data?.CollectionCreator}/Original/NFT/${data?.field[2]?.NFTOrginalImage}`}
                                                                                        type={
                                                                                            data?.field[2]?.CompressedFile
                                                                                                ? data?.field[2]?.CompressedFile?.includes(".webp")
                                                                                                    ? "image"
                                                                                                    : data?.field[2]?.CompressedFile.includes(".webm")
                                                                                                        ? "video"
                                                                                                        : "audio"
                                                                                                : data?.field[2]?.CompressedFile
                                                                                        } alt="images"
                                                                                    />
                                                                                </NavLink>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div class="col-md-12">
                                    <div class="btn-loadmore mt17 mb-5">
                                        <a href="#" class="tf-button loadmore" onClick={() => Loadmore("collection")}>Load More</a>
                                    </div>

                                </div>


                            </section>
                        </>
                    )
                    : (<>
                    </>)
                }
            </div>
            <Footer />
        </>
    )
}