import React, { useEffect } from 'react'
import { MdVerified } from "react-icons/md";
import Aos from 'aos';
import config from '../views/config/config';
import ImgAudVideo from '../separate/ImgAudVideo';
import c1image1 from '../assets/evolvex/images/c1image1.png'
import c1image2 from '../assets/evolvex/images/c1image2.png'
import c1image3 from '../assets/evolvex/images/c1image3.png'
import { Link } from 'react-router-dom';
const CollectionCard = (props) => {
    console.log("propsasdasdas", props);
    const { Profile, WalletAddress, DisplayName,bg, topNFTs, CustomUrl, rightImageTop, rightImageBottom } = props.item
    useEffect(() => {
        Aos.init();
    }, [])
    return (
        <Link to={`/profile/${CustomUrl}`}>
        <div className={`ev-collectioncard ${bg}`} data-aos="fade-up" data-aos-offset="300">
            <div className='ev-collecion__header'>
                <div className='ev-collection__profile'>
                    <img src={Profile
                                                ? `${config.IMG_URL}/user/${WalletAddress}/profile/${Profile}`
                                                : config.profile} alt={DisplayName}  />
                </div>
                <div className='ev-collection__profile--details'>
                    <h5>
                        Owned by
                    </h5>
                    <div className='ev-collection__profile--name'>
                        <p>
                            {DisplayName}
                        </p>
                        <MdVerified fill="#3897f0" />
                    </div>
                </div>

            </div>
            {console.log("topNFTstopNFTstopNFTs",topNFTs)}
            <div className='ev-collection__body'>
                <div className='row h-100 mx-auto'>
                    <div className='col-6 ev-collection__col-left'>
                        <div className='ev-collection__body--left'>
                          {topNFTs && topNFTs.length > 1 ? 
                        <ImgAudVideo
                                file={`${config.IMG_URL}/nft/${WalletAddress}/Compressed/NFT/${topNFTs[0].CompressedFile}`}
                                origFile={config.IPFS + topNFTs[0].NFTOrginalImageIpfs}
                                //{`${config.IMG_URL}/nft/${WalletAddress}/Original/NFT/${topNFTs.OriginalFile}`}
                                thumb={`${config.IMG_URL}/nft/${WalletAddress}/Compressed/NFT_THUMB/${topNFTs[0].CompressedThumbFile}`}
                                type={ 
                                    topNFTs[0].CompressedFile
                                    ? topNFTs[0].CompressedFile?.includes(
                                        ".webp"
                                      )
                                      ? "image"
                                      : topNFTs[0].CompressedFile.includes(
                                          ".webm"
                                        )
                                      ? "video"
                                      : "audio"
                                    : topNFTs[0].CompressedFile
                                }
                              /> 
                              :
                            <img src={c1image1} alt='banner' />
                            }
                        </div>

                    </div>
                    <div className='col-6 ev-collection__col-right'>
                        <div className='ev-collection__body--right'>
                            <div className='ev-collection__right--top'>
                            {topNFTs && topNFTs.length > 2 ? 
                        <ImgAudVideo
                                file={`${config.IMG_URL}/nft/${WalletAddress}/Compressed/NFT/${topNFTs[1].CompressedFile}`}
                                origFile={config.IPFS + topNFTs[1].NFTOrginalImageIpfs}
                                //{`${config.IMG_URL}/nft/${WalletAddress}/Original/NFT/${topNFTs.OriginalFile}`}
                                thumb={`${config.IMG_URL}/nft/${WalletAddress}/Compressed/NFT_THUMB/${topNFTs[1].CompressedThumbFile}`}
                                type={ 
                                    topNFTs[1].CompressedFile
                                    ? topNFTs[1].CompressedFile?.includes(
                                        ".webp"
                                      )
                                      ? "image"
                                      : topNFTs[1].CompressedFile.includes(
                                          ".webm"
                                        )
                                      ? "video"
                                      : "audio"
                                    : topNFTs[1].CompressedFile
                                }
                              /> 
                              :
                            <img src={c1image2} alt='collection' />
                            }
                            </div>
                            {console.log("topNFTs[2]",topNFTs[2])}
                            <div className='ev-collection__right--bottom'>
                            {topNFTs && topNFTs.length > 2 ? 
                        <ImgAudVideo
                                file={`${config.IMG_URL}/nft/${WalletAddress}/Compressed/NFT/${topNFTs[2].CompressedFile}`}
                                origFile={config.IPFS + topNFTs[2].NFTOrginalImageIpfs}
                                //{`${config.IMG_URL}/nft/${WalletAddress}/Original/NFT/${topNFTs.OriginalFile}`}
                                thumb={`${config.IMG_URL}/nft/${WalletAddress}/Compressed/NFT_THUMB/${topNFTs[2].CompressedThumbFile}`}
                                type={ 
                                    topNFTs[2].CompressedFile
                                    ? topNFTs[2].CompressedFile?.includes(
                                        ".webp"
                                      )
                                      ? "image"
                                      : topNFTs[2].CompressedFile.includes(
                                          ".webm"
                                        )
                                      ? "video"
                                      : "audio"
                                    : topNFTs[2].CompressedFile
                                }
                              /> 
                              :
                            <img src={c1image3} alt='collection1' />
                            }
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div >
        </Link>
    )
}

export default CollectionCard