/** npm import */

import React, { useContext, useEffect, useState ,useRef} from 'react'
import { NavLink, Link, useParams } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Countdown, { zeroPad } from "react-countdown";

/** local file import */

// import logo from '../assets/images/evolvex/logo.png'
import logo from '../assets/evolvex/images/newwhitelogo.png'
import { address_showing, axiosFunc } from "../actions/common";
import Card from "./Card.js";
import { GlobalContext } from '../context/context';
import EvHeader from '../separate/EvHeader';
import EvFooter from '../separate/EvFooter';
import config from "./config/config";
import AuctionCard from '../ cards/AuctionCard';
import creatorProfileOne from '../assets/evolvex/images/creatorprofile1.png'
import creatorProfileTwo from '../assets/evolvex/images/creatorprofile2.png'
import creatorProfileThree from '../assets/evolvex/images/creatorprofile3.png'
import creatorProfileFour from '../assets/evolvex/images/creatorprofile4.png'
import creatorProfileFive from '../assets/evolvex/images/creatorprofile5.png'
import creatorProfileSix from '../assets/evolvex/images/creatorprofile6.png'
import creatorBannerOne from '../assets/evolvex/images/creatorbanner1.png'
import creatorBannerTwo from '../assets/evolvex/images/creatorbanner2.png'
import creatorBannerThree from '../assets/evolvex/images/creatorbanner3.png'
import creatorBannerFour from '../assets/evolvex/images/creatorbanner4.png'
import creatorBannerFive from '../assets/evolvex/images/creatorbanner5.png'
import creatorBannerSix from '../assets/evolvex/images/creatorbanner6.png'
import CreatorCard from '../ cards/CreatorCard';
import CollectionCard from '../ cards/CollectionCard';
import collectionProfile from '../assets/evolvex/images/collectionprofile.png'
import c1image1 from '../assets/evolvex/images/c1image1.png'
import c1image2 from '../assets/evolvex/images/c1image2.png'
import c1image3 from '../assets/evolvex/images/c1image3.png'
import c2image1 from '../assets/evolvex/images/c2image1.png'
import c2image2 from '../assets/evolvex/images/c2image2.png'
import c2image3 from '../assets/evolvex/images/c2image3.png'
import c3image1 from '../assets/evolvex/images/c3image1.png'
import c3image2 from '../assets/evolvex/images/c3image2.png'
import c3image3 from '../assets/evolvex/images/c3image3.png'
import TrendingCard from '../ cards/TrendingCard';
import NFTcard from '../ cards/NFTcard';

import circleAnimation from '../assets/evolvex/images/2q.gif'
import aigifone from '../assets/evolvex/images/ai-gif-one.gif'

import auction1 from '../assets/evolvex/images/auction1.gif'
import auction2 from '../assets/evolvex/images/auction2.gif'
import auction3 from '../assets/evolvex/images/auction3.png'
import auction4 from '../assets/evolvex/images/auction4.png'
import auction5 from '../assets/evolvex/images/auction5.png'
import auction6 from '../assets/evolvex/images/auction6.png'
import auction7 from '../assets/evolvex/images/auction7.png'
import auction8 from '../assets/evolvex/images/auction8.png'
import auction9 from '../assets/evolvex/images/auction9.png'
import auction10 from '../assets/evolvex/images/auction10.png'
import Header from '../separate/Header';
import { useSelector } from "react-redux";
import { Token_List_Func ,TopCreatorApi,CollectionByCreator,TopNftCreatorsNfts } from "../actions/axioss/nft.axios";
import NoData from "./seperatemodals/nodata.jsx";
import { getCmsContent, getarticle } from "../actions/axioss/cms.axios";
import ImgAudVideo from "../separate/ImgAudVideo";
import { Getpromotedtoken } from "../actions/axioss/nft.axios";
import { SearchAction } from "../actions/axioss/user.axios";
import AuctionCard1 from '../ cards/AuctionCard1.js';
/** code start */

/** start of top creators data */


const topCreatorsData = [
    {
        id: 1,
        profileImage: creatorProfileOne,
        bannerImage: creatorBannerOne,
        name: "Gradient NFT’s",
        address: "0xd77b09...df95easd"
    },
    {
        id: 2,
        profileImage: creatorProfileTwo,
        bannerImage: creatorBannerTwo,
        name: "Gradient NFT’s",
        address: "0xd77b09...df95easd"
    },
    {
        id: 3,
        profileImage: creatorProfileThree,
        bannerImage: creatorBannerThree,
        name: "Gradient NFT’s",
        address: "0xd77b09...df95easd"
    },
    {
        id: 4,
        profileImage: creatorProfileFour,
        bannerImage: creatorBannerFour,
        name: "Gradient NFT’s",
        address: "0xd77b09...df95easd"
    },
    {
        id: 5,
        profileImage: creatorProfileFive,
        bannerImage: creatorBannerFive,
        name: "Gradient NFT’s",
        address: "0xd77b09...df95easd"
    },
    {
        id: 6,
        profileImage: creatorProfileSix,
        bannerImage: creatorBannerSix,
        name: "Gradient NFT’s",
        address: "0xd77b09...df95easd"
    },
]

const collectionCardData = [
    {
        id: 1,
        profile: collectionProfile,
        name: "Robert Fox",
        bg: "yellow-200",
        leftImage: c1image1,
        rightImageTop: c1image2,
        rightImageBottom: c1image3,
    },
    {
        id: 2,
        profile: collectionProfile,
        name: "Robert Fox",
        bg: "blue-200",
        leftImage: c2image1,
        rightImageTop: c2image2,
        rightImageBottom: c2image3,
    },
    {
        id: 3,
        profile: collectionProfile,
        name: "Robert Fox",
        bg: "yellow-300",
        leftImage: c3image1,
        rightImageTop: c3image2,
        rightImageBottom: c3image3,
    },
]
const hotAuctionCardData = [
    {
        id: 1,
        profileOne: auction9,
        profileTwo: auction10,
        banner: auction1
    },
    {
        id: 2,
        profileOne: auction9,
        profileTwo: auction10,
        banner: auction2
    },
    {
        id: 3,
        profileOne: auction9,
        profileTwo: auction10,
        banner: auction3
    },
    {
        id: 4,
        profileOne: auction9,
        profileTwo: auction10,
        banner: auction4
    },
    {
        id: 5,
        profileOne: auction9,
        profileTwo: auction10,
        banner: auction5
    },
    {
        id: 6,
        profileOne: auction9,
        profileTwo: auction10,
        banner: auction6
    },
    {
        id: 7,
        profileOne: auction9,
        profileTwo: auction10,
        banner: auction7
    },
    {
        id: 8,
        profileOne: auction9,
        profileTwo: auction10,
        banner: auction8
    },

]
/** end of top creators data */

const Landing = () => {

    const {
        bannerSectionOnly,
        isZoomOut,
        hideText,
        enableMidLogo,
        enableTopLogo,
        enableContent,
        handleHomeExplore,
        enableHeaderLogo } = useContext(GlobalContext);

    const [activeAuctionTab, setActiveAuctionTab] = useState('All')
    const [loadingstate, setLoadingstate] = useState(true);
    const [filter, setFilter] = useState("recentcreated");
    var [filtershow, setFiltershow] = useState("Recently Created");
    const [CreateCollectionState, SetCreateCollectionState] = useState({
        All: [],
        tab: "All",
      });
      const [HotAuctionData, SetHotAuction] = useState({
        All: [],
        tab: "All",
      });
    const { Categorys } = useSelector((state) => state.LoginReducer);
    const { payload } = useSelector((state) => state.LoginReducer.User);
    const { Category } = useParams();
    const [TopCreator, SetTopCreator] = useState([]);
    const [creatorstatus, setCreator] = useState(false);
    const [category, setCategory] = useState("All");
    const [hotactionstate, SetHotAuctionstate] = useState(true);
    const [hotsalestate, SetHotsalestate] = useState(true);
    const [homecontentmidd, setHomecontentmidd] = useState([]);
    const [blogstate, setblogstate] = useState(false);
    const [blog, setBlog] = useState([]);
    const [homecontent, setHomecontent] = useState([]);
    const [promotedToken, setPromotedtoken] = useState({});
    const [bannerstate, setBannerstatus] = useState(true);
    const [TopNftsDet, setTopNftsDet] = useState(true);
    const [cms1,setcms1] = useState({})
    const [cms2,setcms2] = useState({})
    const [cms3,setcms3] = useState({})
    const [cms4,setcms4] = useState({})
    const renderer = ({ days, hours, minutes, seconds }) => {
        return (
            <div className='ev-trending__calender'>
                {/* <p >{days < 10 ? `0${days}` : days}</p>
                <p>D</p> <p>:</p> */}
                {/* <div >{hours < 10 ? `0${hours}` : hours}</div>
                <p>H</p> <p>:</p>
                <p >{minutes < 10 ? `0${minutes}` : minutes}</p>
                <p>M</p> <p>:</p> */}
                {/* <p >{seconds < 10 ? `0${seconds}` : seconds}</p>
                <p>s</p> */}
                <div className='ev-trending__count'>
                    <div className='ev-trending__hours'>
                        <div className='ev-trending__count--bg'>
                            <p className='ev-trending__time--count'>
                                {hours < 10 ? `0${hours}` : hours}
                            </p>
                            <p className='ev-trending__time--name'>Hours</p>
                        </div>
                    </div>
                    <div className='ev-trending__mins'>
                        <div className='ev-trending__count--bg'>
                            <p className='ev-trending__time--count'>
                                {minutes < 10 ? `0${minutes}` : minutes}
                            </p>
                            <p className='ev-trending__time--name'>minutes</p>
                        </div>
                    </div>
                    <div className='ev-trending__seconds'>
                        <div className='ev-trending__count--bg'>
                            <p className='ev-trending__time--count'>
                                {seconds < 10 ? `0${seconds}` : seconds}
                            </p>
                            <p className='ev-trending__time--name'>seconds</p>
                        </div>
                    </div>
                </div>

            </div>
        )
    }


    // const [bannerSectionOnly, setBannerSectionOnly] = useState(true);
    // const [isZoomOut, setIsZoomOut] = useState(false);
    // const [hideText, setHideText] = useState(false);
    // const [enableMidLogo, setEnableMidLogo] = useState(false);
    // const [enableTopLogo, setEnableTopLogo] = useState(false);
    // const [enableContent, setEnableContent] = useState(false)

    // const handleHomeExplore = () => {
    //     setHideText(true)
    //     setTimeout(() => {
    //         setIsZoomOut(true)
    //         setEnableMidLogo(true)
    //     }, 2000);
    //     setTimeout(() => {
    //         setEnableMidLogo(false)
    //         setEnableTopLogo(true)
    //     }, 4000);
    //     setTimeout(() => {
    //         setEnableContent(true)
    //     }, 4500);
    // }
    useEffect(() => {
        AOS.init();
    }, [])

    useEffect(() => {
        getarticles();
        Getandselldata();
        Getandsellnft();
        Getandsellnft1();
        Getandsellnft2();
        Getandsellnft3();
        Getandsellnft4();
        TopCreatorFunc();
        HotAuction(1, "All");
        HotSales("All");
        // // Collectionlist('All')
        CollectionByCreate("All");
        GetPromtion();
        TopNft()
      }, []);

    useEffect(() => {
        // if(filter?.auction)
        if (
          HotAuctionData.filter !== undefined &&
          filter.auction !== HotAuctionData.filter
        ) {
          HotAuction("hi", HotAuctionData.tab);
        }
        if (SaleData.filter !== undefined && filter.sale !== SaleData.filter) {
          HotSales(SaleData.tab);
        }
        if (
          CreateCollectionState.filter !== undefined &&
          filter.collection !== CreateCollectionState.filter
        ) {
          CollectionByCreate(CreateCollectionState.tab);
        }
      }, [filter]);
    console.log("category", payload, Category);

    const [TabName, SetTabName] = useState("All");
    var LikeForwardRef = useRef();
    const [LikedTokenList, setLikedTokenList] = useState([]);
    const [Tokens, SetTokens] = useState({
      All: { loader: true, page: 1, list: [] },
    });
    const [SaleData, SetSaleData] = useState({
        All: [],
      });
    const Tabname = (newValue) => {
      SetTabName(newValue);
    };
    useEffect(() => {
      if (Category) {
        if (Category !== TabName) {
          SetTabName(Category);
          Explore(1, Category);
        }
      } else {
        Explore();
      }
    }, [Category]);
  
    useEffect(() => {
      // if (typeof Tokens[TabName] == "undefined") {
      if (
        typeof Tokens[TabName] == "undefined" ||
        Tokens[TabName].filter !== filter
      ) {
        Tokens[TabName] = { page: 1, list: [], loader: false };
        SetTokens(Tokens);
        Explore(1, TabName);
      }
    }, [TabName, filter]);
  
    const Explore = async (data, tab) => {
      setLoadingstate(true);
  
      var page = data ? data : Tokens[TabName]?.page;
      var SendDATA = {
        TabName: tab ? tab : TabName,
        limit: 4,
        ProfileUrl: payload?.ProfileUrl ? payload.ProfileUrl : "",
        page: page ?? 1,
        from: "Explore",
        filter: filter,
      };
      let Resp = await Token_List_Func(SendDATA);
      console.log("Check datad", Resp);
      if (Resp?.data) {
        SetTokens({
          ...Tokens,
          ...{
            [TabName]: {
              list: [...Tokens[TabName].list, ...Resp.data],
  
              loader:
                Resp.data.length == 0 ||
                  (SendDATA.TabName == "usercollection" && Resp.cursor == null)
                  ? false
                  : true,
              page: Tokens[TabName].page,
              filter: filter,
            },
          },
        });
        setLoadingstate(false);
      } else
        SetTokens({
          ...Tokens,
          ...{
            [TabName]: {
              list: Tokens[TabName].list,
              loader: false,
              page: Tokens[TabName].page,
              filter: filter,
            },
          },
        });
      setLoadingstate(false);
    };
    const LoadMore = () => {
      Tokens[TabName].page = Tokens[TabName].page + 1;
      SetTokens(Tokens);
      Explore(Tokens[TabName].page);
    };
    function LikeList(data) {
      setLikedTokenList(data);
    }

 

    const TopCreatorFunc = async () => {
        var resp = await TopCreatorApi();
        console.log("topcreator", resp);
        if (resp?.data?.length > 8) {
          SetTopCreator(resp?.data.slice(0, 8));
          setCreator(true);
        } else {
          SetTopCreator(resp?.data);
          setCreator(true);
        }
      };

      const CollectionByCreate = async (data) => {
        var SendDATA = {
          tab: data,
          limit: 4,
          ProfileUrl: "",
          page: 1,
          from: "home",
          filter: filter.collection,
        };
        let Resp = await CollectionByCreator(SendDATA);
        // console.log('fhgngfngf',Resp)
        SetCreateCollectionState({
          ...CreateCollectionState,
          ...{
            [data]: Resp?.data,
            tab: data,
            filter: filter.collection,
          },
        });
        setCategory(data);
      };

      const HotAuction = async (data, tabs) => {
        var SendDATA = {
          TabName: tabs,
          limit: 8,
          ProfileUrl: "",
          page: 1,
          from: "Auction",
          filter: filter.auction,
        };
        let Resp = await Token_List_Func(SendDATA);
        if (Resp?.success == "success") {
          SetHotAuction({
            ...HotAuctionData,
            ...{
              [tabs]: Resp?.data,
              tab: tabs,
              filter: filter.auction,
            },
          });
          SetHotAuctionstate(false);
        } else {
          SetHotAuction({
            ...HotAuctionData,
            ...{
              [tabs]: Resp?.data,
              tab: tabs,
              filter: filter.auction,
            },
          });
          SetHotAuctionstate(false);
        }
      };

      const HotSales = async (tabs) => {
        var SendDATA = {
          TabName: tabs,
          limit: 8,
          ProfileUrl: "",
          page: 1,
          from: "Sale",
          filter: filter.sale,
        };
        let Resp = await Token_List_Func(SendDATA);
        console.log("hot sales", Resp, "tabs", tabs);
        if (Resp?.success == "success") {
          SetSaleData({
            ...SaleData,
            ...{
              [tabs]: Resp?.data,
              tab: tabs,
              filter: filter.sale,
            },
          });
          SetHotsalestate(false);
        } else {
          SetSaleData({
            ...SaleData,
            ...{
              [tabs]: Resp?.data,
              tab: tabs,
              filter: filter.sale,
            },
          });
          SetHotsalestate(false);
        }
      };

      const Getandsellnft = async () => {
        var resp = await getCmsContent("homepage_middle");
        console.log("cmslist", resp?.data);
        if (resp?.status) setHomecontentmidd(resp?.data);
      };

      const Getandsellnft1 = async () => {
        var resp = await getCmsContent("aboutus_top");
        console.log("cmslist", resp?.data);
        if (resp?.status) setcms1(resp?.data);
      };

      const Getandsellnft2 = async () => {
        var resp = await getCmsContent("aboutus_middle");
        console.log("cmslist", resp?.data);
        if (resp?.status) setcms2(resp?.data);
      };

      const Getandsellnft3 = async () => {
        var resp = await getCmsContent("aboutus_middle1");
        console.log("cmslist", resp?.data);
        if (resp?.status) setcms3(resp?.data);
      };

      const Getandsellnft4 = async () => {
        var resp = await getCmsContent("aboutus_middle2");
        console.log("cmslist", resp?.data);
        if (resp?.status) setcms4(resp?.data);
      };

      const getarticles = async () => {
        setblogstate(false);
        var resp = await getarticle({ skip: 0, limit: 12 });
        if (resp?.status) {
          setBlog(resp?.data);
          setblogstate(true);
        }
      };

      const Getandselldata = async () => {
        var resp = await getCmsContent("homepage_top");
         console.log("cmslistdfgfd",resp.data)
        if (resp?.status) setHomecontent(resp.data);
      };

      const GetPromtion = async () => {
        // var resp = await Getpromotedtoken()
        var protoken = await Getpromotedtoken();
        console.log("DGJFGJFJD", protoken);
        if (protoken?.success == "success") {
          console.log(protoken?.data[0], "protoken");
          setPromotedtoken(protoken?.data[0]);
          setBannerstatus(false);
        }
      };
      

      const TopNft = async () => {
        // var resp = await Getpromotedtoken()
        var protoken = await TopNftCreatorsNfts();
        console.log("TopNft", protoken);
        if (protoken?.success) {
          console.log(protoken?.data, "protoken");
          setTopNftsDet(protoken?.data);
        }
      };


    return (
        <>
            {/* <EvHeader /> */}
            <Header />
            <div className='evolvex'>
                <section className={` ${isZoomOut ? "ev-banner__zoomout" : "ev-banner"}`}>
                    <div className={`${isZoomOut ? "ev-banner__explore--zoomout" : "ev-banner__explore"}`} onClick={() => handleHomeExplore()} >
                        <div className={` ev-banner__expore-text ${hideText ? 'ev-banner__expore-text--hide' : "ev-banner__expore-text--view"} ${isZoomOut ? 'd-none' : ""}`}>
                            <p>Tap to Explore our <b>Nova</b> Marketplace</p>
                        </div>
                        <div className={`${enableMidLogo ? "ev-banner__midlogo--view" : "ev-banner__midlogo--hide"} ${enableTopLogo && 'd-none'}`}>
                            <img src={logo} />
                        </div>
                        {/* <div className={`${enableTopLogo && !enableHeaderLogo ? "ev-banner__toplogo--view" : "ev-banner__toplogo--hide"} `}>
                            <img src={logo} />
                        </div> */}
                        <div className={`${enableTopLogo ? "ev-banner__toplogo--view" : "ev-banner__toplogo--hide"} `}>
                            <img src={logo} />
                        </div>
                        {/* <div className={`${enableContent ? 'ev-banner__opacity' : "d-none"}`}>
                        </div> */}
                        <div className={`${enableContent ? "ev-banner__content--view" : "d-none"}`}>

                            <div className='ev-banner__content_animation'
                            >
                                {/* <div className='container ev-container px-0'
                                > */}
                                <div className='container ev-container px-0 ev-container__relative'
                                >
                                    <div className='row h-100 align-items-center'>
                                        <div className='col-12 col-xl-6 ev-col-xxl-6 '>
                                            <div className='ev-banner__content--left'>
                                                {!homecontent?.description ?
                                                <h5>
                                                    Buy , Sell NFT's & <br />
                                                    Get Rewards
                                                </h5>
                                                :
                                                <div
                              className="first-p"
                              dangerouslySetInnerHTML={{
                                __html: homecontent?.description,
                              }}
                            ></div>}
                            {!homecontent?.answer ?
                                                <p>NFT Marketplace is the web3 NFT Marketplace Where Traders And collectors earn rewards</p>
                                                :
                                                <div
                              className="first-p"
                              dangerouslySetInnerHTML={{
                                __html: homecontent?.answer,
                              }}
                            ></div>}
                                                <div className='mt-5 d-flex justify-content-center justify-content-xl-start'>
                                                    <Link to="/explore">
                                                    <button className=' ev_bannerBlueBtn'>Explore</button>
                                                    </Link>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-12 col-xl-6 ev-col-xxl-6 mt-5 mt-xl-0 ev-banner__right-col'>
                                        {promotedToken?.CompressedFile ?
                                            <div className='ev-banner__content--right'>
                                                <div className='ev-banner__cardwrapper'>
                                                    <div className='ev-banner__card--left mb-4'>
                                                        <AuctionCard 
                                                        promotedToken = {promotedToken}
                                                        />
                                                    </div>
                                                    <div className='ev-banner__card--center mb-4'>
                                                        <AuctionCard promotedToken = {promotedToken} />
                                                    </div>
                                                    <div className='ev-banner__card--right'>
                                                        <AuctionCard 
                                                        promotedToken = {promotedToken}/>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='ev-banner__content--right'>
                                            <div className='ev-banner__cardwrapper'>
                                                <div className='ev-banner__card--left mb-4'>
                                                    <AuctionCard1 
                                                   
                                                    />
                                                </div>
                                                <div className='ev-banner__card--center mb-4'>
                                                    <AuctionCard1  />
                                                </div>
                                                <div className='ev-banner__card--right'>
                                                    <AuctionCard1 
                                                    />
                                                </div>
                                            </div>
                                        </div>
}
                                        </div>

                                    </div>


                                </div>
                            </div>



                        </div>

                    </div>
                    <div className='d-none'>
                    </div>
                </section >
                {bannerSectionOnly && <>

                    <section className='ev-creators' >
                    {/* {creatorstatus == false ? (
                <> */}
                        <div className='container container-width'>
                            <div className='ev-creators__head'>
                                <h5>Top Creators</h5>
                                {!cms1?.answer ?
                    <>
                                        <p>Dive into the world of exceptional creativity with our monthly showcase of the most innovative creators on Nova. These artists have captivated our community with their unique vision and groundbreaking works in the past 30 days. Explore their collections and get inspired by the forefront of digital artistry.</p>
                                        </>
                                        :
                                        <p
                 
                      dangerouslySetInnerHTML={{
                        __html: cms1?.answer,
                      }}
                    ></p>} 
                                {/* <p>Dive into the world of exceptional creativity with our monthly showcase of the most innovative creators on Nova. These artists have captivated our community with their unique vision and groundbreaking works in the past 30 days. Explore their collections and get inspired by the forefront of digital artistry.</p> */}
                            </div>

                            <div className='ev-creators__cards '>
                                <div className='row'>
                                    {TopCreator?.length > 0 ? (
                                            TopCreator?.map((item) => (
                                            <div className='col-12 col-sm-6 col-lg-4 mb-4' key={item.id}>
                                                <CreatorCard item={item} />
                                            </div>
                                        ))
                                        ) : (
                                            <NoData />
                                          )
                                    }
                                </div>
                            </div>

                            {/* <div className='ev-creators__button d-flex justify-content-center'>
                                <button className='ev-creators__button--view ev_bannerBlueBtn'>
                                    View All
                                </button>
                            </div> */}
                        </div>
                        {/* </>
                               ) : TopCreator?.length > 0 ? (
                                TopCreator?.map((data, index) => {
                                  return (
                                    <div className="col-xl-3 col-lg-4 col-sm-6 col-md-6">
                                      <div className="card card1">
                                        <NavLink
                                          to={`/profile/${data?.CustomUrl}`}
                                          className="text-center"
                                        >
                                          <img
                                            src={
                                              data?.Profile
                                                ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.WalletAddress}`
                                                : config.profile
                                            }
                                            alt="Image"
                                            className="card-images"
                                          />{" "}
                                        </NavLink>
                                        <div className="content text-center all1">
                                          <div className="title">
                                            <NavLink to={`/profile/${data?.CustomUrl}`}>
                                              {data?.DisplayName
                                                ? data?.DisplayName
                                                : address_showing(data?.WalletAddress)}
                                            </NavLink>
                                          </div>
                                          <div className="price">
                                            <span className="price-eth cardvalue">
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <NoData />
                              )} */}

                    </section>

                    <section className='ev-collections'>
                        {/* <div className='container ev-container px-0'> */}
                        <div className='container container-width'>

                            <div className='ev-collections__head'>
                                <h5>Top Collection</h5>
                                {!cms2?.answer ?
                    <>
                                        <p >Dive into the world of exceptional creativity with our monthly showcase of the most innovative creators on Nova. These artists have captivated our community with their unique vision and groundbreaking works in the past 30 days. Explore their collections and get inspired by the forefront of digital artistry.</p>
                                        </>
                                        :
                                        <p 
                      dangerouslySetInnerHTML={{
                        __html: cms2?.answer,
                      }}
                    ></p>} 
                                {/* <p>Dive into the world of exceptional creativity with our monthly showcase of the most innovative creators on Nova. These artists have captivated our community with their unique vision and groundbreaking works in the past 30 days. Explore their collections and get inspired by the forefront of digital artistry.</p> */}
                            </div>

                            <div className='ev-collections__cards '>
                                <div className='row'>
                                    {
                                        TopNftsDet && TopNftsDet.length > 0 && TopNftsDet.map((item) => (
                                            <div className='col-12 col-sm-6 col-lg-4 mb-4' key={item.id}>
                                                <CollectionCard item={item} />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            {/* <div className='ev-creators__button d-flex justify-content-center'>
                                <button className='ev-creators__button--view ev_bannerBlueBtn'>
                                    View All
                                </button>
                            </div> */}
                        </div>

                    </section>

                    <section className='ev-trending'>
                        {/* <div className='container ev-container px-0'> */}
                        <div className='container container-width'>
                            <div className='ev-trending__background'>
                                <div className='ev-trending__star--anim'>
                                </div>
                                <div className='row ev-trending__row mx-auto'>
                                    <div className='col-12 col-lg-6'>
                                        <div className='ev-trending__left'>
                                            <h5 className='ev-trending__title'>
                                                Hot trending on this week
                                            </h5>
                                            {!cms3?.answer ?
                    <>
                                        <p className='ev-trending__desc'>Dive into the world of exceptional creativity with our monthly showcase of the most innovative creators on Nova. These artists have captivated our community with their unique vision and groundbreaking works in the past 30 days. Explore their collections and get inspired by the forefront of digital artistry.</p>
                                        </>
                                        :
                                        <p className='ev-trending__desc'
                      dangerouslySetInnerHTML={{
                        __html: cms3?.answer,
                      }}
                    ></p>} 
                                            {/* <p className='ev-trending__desc'>
                                            Dive into the world of exceptional creativity with our
                    monthly showcase of the most innovative creators on Nova.
                    These artists have captivated our community with their
                    unique vision and groundbreaking works in the past 30 days.
                    Explore their collections and get inspired by the forefront
                    of digital artistry.
                                            </p> */}
                                            {/* <div className='ev-trending__calender'>
                                            <div className='ev-trending__count'>
                                                <div className='ev-trending__count--bg'>
                                                    12
                                                </div>
                                                <p className='ev-trending__time'>Hours</p>
                                            </div>
                                            <div className='ev-trending__count'>
                                                <div className='ev-trending__count--bg'>
                                                    14
                                                </div>
                                                <p className='ev-trending__time'>Hours</p>
                                            </div>
                                            <div className='ev-trending__count'>
                                                <div className='ev-trending__count--bg'>
                                                    <img />
                                                </div>
                                                <p className='ev-trending__time'>Hours</p>
                                            </div>
                                            <div className='ev-trending__count'>
                                            </div>
                                        </div> */}
                                            <Countdown date={Date.now() + 245006600} renderer={renderer} />
                                            <div className='ev-trending__button'>
                                                <button className='ev-trending__button--placebid'>
                                                    <p>Place Bid</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-6 ev-trending__right-col'>
                                    <div className='ev-trending__cards'>
                                           {promotedToken?.CompressedFile ?
                                          
                                            <div className='ev-trending__cards'>
                                                <div className='ev-trending__cards-left'>
                                                        <AuctionCard 
                                                        promotedToken = {promotedToken}
                                                        />
                                                </div>
                                                <div className='ev-trending__cards-right'>
                                                        <AuctionCard promotedToken = {promotedToken} />
                                                </div>
                                            </div>
                                            
                                            :
                                            <div className='ev-trending__cards'>
                                                 <div className='ev-trending__cards-left'>
                                                 <TrendingCard />
                                            </div>
                                            <div className='ev-trending__cards-right'>
                                            <TrendingCard />
                                           </div>
                                           </div>
                                           }
                                         </div>
                                        {/* // <div className='ev-trending__cards'>
                                        //     <div className='ev-trending__cards-left'>
                                        //         <TrendingCard />
                                        //     </div>
                                        //     <div className='ev-trending__cards-right'>
                                        //         <TrendingCard />
                                        //     </div>
                                        // </div> */}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </section>

                    <section className='ev-hotauction'>
                        {/* <div className='container ev-container px-0'> */}
                        <div className='container container-width'>
                            <div className='ev-hotauction__head'>
                                <h5>Hot Auction</h5>
                                {/* <p>Dive into the world of exceptional creativity with our
                    monthly showcase of the most innovative creators on Nova.
                    These artists have captivated our community with their
                    unique vision and groundbreaking works in the past 30 days.
                    Explore their collections and get inspired by the forefront
                    of digital artistry.</p> */}
                            {!cms4?.answer ?
                    <>
                                        <p>Dive into the world of exceptional creativity with our monthly showcase of the most innovative creators on Nova. These artists have captivated our community with their unique vision and groundbreaking works in the past 30 days. Explore their collections and get inspired by the forefront of digital artistry.</p>
                                        </>
                                        :
                                        <p
                      dangerouslySetInnerHTML={{
                        __html: cms4?.answer,
                      }}
                    ></p>} 
                            </div>
                            {/* <div className='ev-hotauction__tab my-5'>
                                <button className={`${activeAuctionTab === "All" ? "active" : ""}`} onClick={() => setActiveAuctionTab('All')}>All</button>
                                <button className={`${activeAuctionTab === "Art" ? "active" : ""}`} onClick={() => setActiveAuctionTab('Art')}>Art</button>
                                <button className={`${activeAuctionTab === "Games" ? "active" : ""}`} onClick={() => setActiveAuctionTab('Games')}>Games</button>
                                <button className={`${activeAuctionTab === "Video" ? "active" : ""}`} onClick={() => setActiveAuctionTab('Video')}>Video</button>
                                <button className={`${activeAuctionTab === "Music" ? "active" : ""}`} onClick={() => setActiveAuctionTab('Music')}>Music</button>
                                <button className={`${activeAuctionTab === "Tickets" ? "active" : ""}`} onClick={() => setActiveAuctionTab('Tickets')}>Tickets</button>
                            </div> */}
                            {/* <div className='ev-hotauction__cards'>
                                <div className='row'>
                                    {
                                        hotAuctionCardData.map((item) => (
                                            <div className='d-flex justify-content-center justify-content-lg-start col-md-6 col-lg-4 col-xl-3 mb-5'>
                                                <NFTcard item={item} />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div> */}
                                <div className="ev-hotauction__tab my-5">
            <div className="row ">
              <div className="col-md-12">
                <div className="top-menu ev-explore__top-menu wow fadeInUp justify-content-center " data-aos="fade-up">
                  <ul className="filter-menu">
                    {/* <Link to="/explore"> */}
                      <li
                        className={TabName == "All" ? "active" : ""}
                        onClick={() => Tabname("All")}
                      >
                        All
                      </li>
                    {/* </Link> */}
                    {Categorys.map((item) => {
                      return (
                        // <Link to="/explore">
                          <li
                            className={`nv-landing__card ${TabName == item.label ? "active" : ""} `}
                            onClick={() => Tabname(item.label)}
                          >
                            <a>{item.label}</a>
                          </li>
                        // </Link>
                      );
                    })}
                    {/* <li className={category == 1 ? "active" : ""} onClick={() => setCategory(1)}><a>3D MODEL</a></li>
                                    <li className={category == 2 ? "active" : ""} onClick={() => setCategory(2)}><a>ANIME/MANGA</a></li>
                                    <li className={category == 3 ? "active" : ""} onClick={() => setCategory(3)}><a>CYBER PUNK</a></li>
                                    <li className={category == 4 ? "active" : ""} onClick={() => setCategory(4)}><a>PIXEL ART</a></li>
                                    <li className={category == 5 ? "active" : ""} onClick={() => setCategory(5)}><a>MUSIC</a></li>
                                    <li className={category == 6 ? "active" : ""} onClick={() => setCategory(6)}><a>ABSTRACT</a></li>
                                    <li className={category == 7 ? "active" : ""} onClick={() => setCategory(7)}><a>2D ARTS</a></li> */}
                  </ul>
             
                </div>
              </div>
            </div>
            <div className="tf-filter-container row" data-aos="fade-up">
              {/* {category == 1 ? */}
              {/* {console.log('likrreeeeexple',LikeForwardRef,setLikedTokenList,LikedTokenList)} */}
              {loadingstate == true ? (
                <>
                  <div className="text-centre">
                    <h3>Loading...</h3>
                    {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
                    {/* <p>Nothing for ReSale at this time</p> */}
                    <div className="load-more">
                      {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                    </div>
                  </div>
                </>
              ) :

   

                TabName && Tokens[TabName]?.list.length > 0 ? (
                  Tokens[TabName]?.list.map((item, index) => (
                    <>
                    {/* // <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"> */}
                    {index < 4 && (
                    <div className="d-flex justify-content-center justify-content-lg-start col-md-6 col-lg-4 col-xl-3 mb-5">

                      {console.log("datainexplore", item)}
                      <Card
                        product={item}
                        index={index}
                        LikeList={LikeList}
                        LikedTokenList={LikedTokenList}
                        setLikedTokenList={setLikedTokenList}
                        LikeForwardRef={LikeForwardRef}
                      />
                    </div> )
                    }
                    </>
                  ))
                )

              
                  : (
                    <NoData />
                  )}
            </div>
            {/* {console.log('gfbvfbfb',Tokens,Tokens[TabName]?.loader)} */}
            {Tokens[TabName]?.loader && (
              <div className="d-flex justify-content-center">
                {/* <div className="btn-loadmore mt17">
                  <Button className="tf-button loadmore" onClick={LoadMore}>
                    Load More
                  </Button>
                </div> */}
<Link to="/explore">
                <button onClick={LoadMore} className="ev-button__loadmore my-5">
                  Load More
                </button>
                </Link>
              </div>
            )}
          </div>
                        </div>
                    </section>
                    <section className='ev-image d-flex align-items-center position-relative ev-image_beforeCont'>
                        <div className='ev-image__gif--bg'>

                        </div>
                        <div className='container container-width'>
                            <div className='row ev-image__row align-items-center flex-column-reverse flex-xl-row'>
                                <div className='col-xl-6 d-flex justify-content-center justify-content-xl-start align-items-center order-1 order-xl-0 mt-5'>
                                    <div className='ev-image__left'>
                                        <div className='ev-image__left--start '>
                                            <div className='ev-image__start--top'>
                                                <img src={aigifone} alt="gif" className='ev-fit__image--cover ev-relativeIndxing' />
                                            </div>
                                            <div className='ev-image__start--bottom'>
                                                <img src={c1image2} alt="gif" className='ev-fit__image--cover' />
                                            </div>
                                        </div>
                                        <div className='ev-image__left--end'>
                                            <div className='ev-image__end--top'>
                                                <img src={c1image2} alt="gif" className='ev-fit__image--cover' />
                                            </div>
                                            <div className='ev-image__end--bottom'>
                                                <img src={circleAnimation} alt="circle" className='ev-image__bg--gif' />
                                                <div className='ev-image__bg--wrapper'>
                                                    <img src={c1image3} alt="nft" className='ev-fit__image--cover' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-xl-6 order-0 order-xl-1 mb-5 mb-lg-0'>
                                    <div className='ev-image__right'>
                                    {!homecontentmidd?.description ?
                                     <h5>Explore NFTs</h5> 
                                     :
                                        <h5
                      dangerouslySetInnerHTML={{
                        __html: homecontentmidd?.description,
                      }}
                    ></h5>} 
                    {!homecontentmidd?.answer ?
                    <>
                                        <p className='m-0 ev-image__cmnDesc'>Dive into the world of exceptional creativity with our monthly showcase of the most innovative creators on Nova. These artists have captivated our community with their unique vision and groundbreaking works in the past 30 days. Explore their collections and get inspired by the forefront of digital artistry.</p>
                                        </>
                                        :
                                        <div
                      className="home_banne_tex_dec"
                      dangerouslySetInnerHTML={{
                        __html: homecontentmidd?.answer,
                      }}
                    ></div>}
                                        <div>
                                            {/* <button className='ev-image__button-generate mt-5'>Generate</button> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <EvFooter />
                </>
                }

            </div >

        </>
    )
}

export default Landing